@mixin mobile ($orientation: false) {
  @if $orientation {
    @media only screen and (max-width: 800px) and (orientation: $orientation) {
      @content;
    }
  } @else {
    @media only screen and (max-width: 800px) {
     @content;
    }
  }
}

@mixin desktop {
  @media only screen and (min-width: 800px) {
    @content;
  }
}
