.project-gallery {
  @include filet(top);
  position: relative;
  padding: gutter() 0;

  @include mobile {
    margin: 0 gutter(-1);
    border: none;
    &::before {
      @include filet(top);
      content: '';
      position: absolute;
      top: 0;
      left: gutter();
      right: gutter();
    }
  }

  @include desktop {
    &::before,
    &::after {
      @include easing(300ms, opacity);
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      width: gutter(2);
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, $white, rgba(255, 255, 255, 0.001));
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, $white, rgba(255, 255, 255, 0.001));
    }

    &[data-overflow-left=true]::before,
    &[data-overflow-right=true]:after { opacity: 1; }
  }

  &__items {
    @include invisible-scroll;
    display: flex;

    @include mobile {
      @include scrolling-padding-right-hack;
      padding: 0 gutter();
    }

  }

  &__item {
    @include easing(300ms, filter);
    margin-right: gutter();

    img {
      width: auto;
      height: 20rem;
    }

    figcaption {
      display: none;
    }
  }
}
