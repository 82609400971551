.menu {
  @include easing(300ms, color);
  @include no-select;

  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: gutter();
  line-height: gutter();

  font-size: $font-size--sm;
  font-weight: bold;
  text-transform: lowercase;

  background: $white;
  color: var(--color);

  // Optical alignment
  & > * { margin-top: 1px; }

  svg.logo {
    cursor: pointer;
    margin-top: -0.35em;
    height: 1.05em;
    width: auto;
    fill: currentColor;

    rect {
      @include easing(300ms, transform);
      &:last-of-type { transform-origin: center top; }
      &:first-of-type { transform-origin: center bottom; }
    }

    &:hover rect:last-of-type {
      transform: scale(1, 1.9);
    }

    &:hover rect:first-of-type {
      transform: scale(1, 0.5);
    }
  }

  &.is-inverted {
    --color: #{$white};
    background: transparent;
  }

  a:not(:hover) {
    border-color: transparent;
  }

  @include mobile {
    a { border: 0; }
  }

  &, &__links {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    margin-left: gutter();
  }

  &__link.has-icon {
    @include desktop {
      .icon { display: none; }
    }

    @include mobile {
      a { border: 0; }
      span { display: none; }
    }
  }
}
