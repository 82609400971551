html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Foldera', sans-serif;
  font-size: $font-size;
  line-height: $line-height;
  font-variant-ligatures: none;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: $white;
}

::selection  {
  background: $accent;
  color: $white;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;

  figure & {
    width: 100%;
    height: auto;
  }
}

svg.icon {
  height: 1.1em;
  fill: currentColor;
  vertical-align: middle;
  margin-top: -3px;
}

a {
  @include easing(300ms, border);
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid;
}

u {
  text-decoration: none;
  border-bottom: 1px solid;
}

p {
  margin-bottom: 1em;
}

img[data-lazyload]{
  opacity: 0.1;
  background: $black;
  &[data-loaded=true] {}
  &[data-fully-loaded] {
    @include easing(300ms, opacity);
    opacity: 1;
    background: rgba($black, 0.1);
  }
}
