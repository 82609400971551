.project-card {
  @include filet(top);
  padding: gutter() 0;
  border-bottom: none;

  display: grid;
  gap: gutter();
  grid-template-columns: cols(7) 1fr;

  @include mobile {
    gap: unset;
    grid-template-columns: 1fr;
  }

  // See below for focus style
  &:focus { outline: 0; }

  // Optical alignment
  &__title, &__date {
    margin-top: 0.4rem;
    margin-bottom: -0.4rem;
  }

  &__title {
    font-size: $font-size;
    font-weight: normal;
  }

  &__title span {
    @include easing(300ms, border-color);
    border-bottom: 1px solid transparent;
  }

  &:hover &__title span,
  &:focus &__title span {
    border-bottom-color: currentColor;
  }

  &__date {
    text-align: right;

    @include mobile {
      text-align: left;
      font-size: $font-size--sm;
      color: $dimmed;
    }
  }
}
