.filters {
  @include no-select;

  z-index: 2;
  position: sticky;
  top: gutter(2);

  font-size: $font-size--sm;
  font-weight: bold;
  text-transform: lowercase;
  overflow: hidden;

  color: $accent;
  background: $white;

  &.is-sticky {}
  &.has-active {}

  a {
    border: 0;
  }

  &__items {
    @include invisible-scroll(x);
    @include scrolling-padding-right-hack;
    position: relative;
    padding: gutter();
    display: flex;
    justify-content: flex-start;
  }

  &__item a,
  &__item.is-active::after {
    @include easing(120ms, opacity);

    display: block;
    border: $filet-weight solid;
    padding: 0.5rem 0.5rem 0.1rem 0.5rem;
    white-space: nowrap;
  }

  &__item {
    margin-right: gutter(1/2);
  }

  &.has-active &__item:not(.is-active):not(:hover) a {
    opacity: 0.3;
  }

  html.js &__item.is-active {
    display: flex;
    cursor: pointer;
    &::after {
      content: '\00d7';
      border-left: 0;
      text-align: center;
      width: gutter(2);
    }
  }

  #ajap {
    position: absolute;
    top: gutter(0);
    right: 0;

    height: 100%;
    padding: gutter(0.5);
    padding-left: gutter(10);
    background: linear-gradient(-90deg, $white 50%, rgba($white, 0));

    pointer-events: none;

    a {
      pointer-events: auto;
      border: 0;
    }

    svg {
      width: auto;
      height: 100%;
      fill: currentColor;
    }
  }
}

main:not([data-barba-namespace='home']) #ajap {
  display: none;
}
