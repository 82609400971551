.pswp {
  &__bg {
    background-color: $white;
  }

  &__img {
    background: $white;
    padding: gutter();
  }

  &__counter {
    padding: gutter(1/2) gutter();
    color: $black;
  }

  &__caption {
    @include no-select;
    background-color: $white !important;
    opacity: 0.9;
    &__center {
      font-size: $font-size;
      line-height: $line-height;
      text-align: center;
      color: $black !important;
      padding: gutter();
    }
  }

  &__button {
    filter: invert(1);

    &--arrow--left,
    &--arrow--right {
      margin: 0;
    }
  }

  &__top-bar {
    padding: gutter(1/2);
    background: none !important;
    opacity: 1 !important;
    display: table !important;
  }
}
