@font-face {
  font-family: 'Foldera';
  font-display: auto;
  src: url('../fonts/foldera-regular.woff2') format('woff2'),
       url('../fonts/foldera-regular.woff') format('woff'),
       url('../fonts/foldera-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Foldera';
  font-display: auto;
  src: url('../fonts/foldera-bold.woff2') format('woff2'),
       url('../fonts/foldera-bold.woff') format('woff'),
       url('../fonts/foldera-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
