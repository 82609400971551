.project {
  @include fade-in;
  padding: 0 gutter();
  margin-top: gutter(2);

  &__header {}

  &__header .project-card {
    border-top: 0;
  }

  // Force title to be underlined
  &__header .project-card__title span {
    border-bottom: 1px solid;
  }

  .project-gallery {}

  &__content {
    display: grid;
    grid-template-columns: cols(7) cols(5);
    gap: gutter();

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__content > * {
    @include filet(top);
    padding-top: gutter();
  }

  &__content &__text {
    p, ul, ol {
      margin-bottom: gutter();
      &:empty { display: none; }
    }

    ul {
      list-style: square outside;
      padding-left: gutter();
      p { margin: 0; }
    }

    h2 {
      color: $dimmed;
      font-size: $font-size--sm;
      font-weight: normal;

      &:not(:first-child) {
        margin-top: gutter(2);
        margin-bottom: gutter();
      }
    }

    // Cannot target h2:first-child because sometimes Kirby adds a <p></p> at
    // the beginning of the text
    p:empty:first-child + h2 {
      margin-top: 0;
    }
  }

  // Reduce the content width to get a better optical gutter
  @include desktop {
    &__content &__text > * { padding-right: gutter(); }
  }

  &__meta {
    color: $black;
    margin-bottom: gutter();
    font-size: $font-size--sm;

    &:not(:first-child) {
      @include filet(top);
      padding-top: gutter();
    }

    &::before {
      content: attr(data-label);
      color: $dimmed;
      display: block;
      margin-bottom: 0.5em;
    }
  }

  &__related {
    margin-top: gutter(5);

    @include mobile {
      @include filet(top);
      margin-top: gutter(6);
      padding-top: gutter();
      .project-entry:first-child {
        border: 0;
      }
    }

    &::before {
      content: 'Projets liés';
      display: block;
      margin-bottom: 0.5em;
      color: $dimmed;
      font-size: $font-size--sm;
    }
  }
}
