@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';
@import 'sass/devices';

$debug-grid: false;

$black: #212121;
$dimmed: #808080;
$filet: #EEEEEE;
$white: #FFFFFF;
$accent: var(--color);

:root {
  --color: #55D7D2;
  --color--safari-gradient-hack: #55D7D200;

  --gutter: 1.8rem;
  --font-size: 2.1rem;
  --font-size--sm: 1.8rem;
  --line-height: 1.5;
  --filet-weight: 3px;

  @include mobile {
    --font-size: 1.8rem;
    --font-size--sm: 1.6rem;
    --filet-weight: 2.5px;
    --gutter: 1.6rem;
  }

  @media (min-width: 1600px) {
    --font-size: 2.2rem;
    --font-size--sm: 2.0rem;
    --gutter: 2.5rem;
  }

  @media (min-width: 1920px) {
    --font-size: 2.4rem;
    --font-size--sm: 2.2rem;
    --gutter: 2.5rem;
  }
}

$font-size: var(--font-size);
$font-size--sm: var(--font-size--sm);
$line-height: var(--line-height);
$filet-weight: var(--filet-weight);

@mixin filet ($dir: top, $dir2: null) {
  border-#{$dir}: $filet-weight solid $filet;
  @if $dir2 {
    border-#{$dir2}: $filet-weight solid $filet;
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes saturate {
  from { filter: grayscale(100%); }
  to { filter: none; }
}

@mixin fade-in {
  animation: fade-in 1s linear;
}

@function cols ($width) {
  @return calc(#{$width} * (100vw - #{gutter()}) / 12 - #{gutter()});
}

@function gutter ($multiplier: 1) {
  @return calc(var(--gutter) * #{$multiplier});
}

@import 'sass/base';
@import 'sass/grid';

@import 'components/About';
@import 'components/Filters';
@import 'components/Landing';
@import 'components/Menu';
@import 'components/Photoswipe';
@import 'components/Project';
@import 'components/ProjectCard';
@import 'components/ProjectGallery';
@import 'components/Projects';

html:not(.js) {
  [data-template='home'] .menu.is-inverted,
  [data-template='home'] .filters {
    color: $accent;
    background: $white;
  }
}

@keyframes landing-appears {
  from { background-size: 100% 1000%; }
  to { background-size: 100% 100%; }
}

html.js body::before {
  z-index: 3;
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color);
  will-change: opacity;
  opacity: 1;

  background: $accent;
}

html.js body.is-loading {
  overflow: hidden;

  .menu {
    z-index: 4;
    background: transparent;
    color: $white;
  }
  .menu__link {
    display: none;
  }

  .menu .logo {
    pointer-events: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

html.js body:not(.is-loading)::before {
  @include easing(1s, opacity);
  pointer-events: none;
  opacity: 0;
}
