.landing {
  @include no-select;

  position: relative;
  color: $white;
  background: $accent;
  height: calc(var(--vh, 1vh) * 100 - #{gutter(4)} - 6px);
  pointer-events: none;
  overflow: hidden;

  @include mobile { height: calc(var(--vh, 1vh) * 100 - #{gutter(4)} - 1px); }
  @media (min-width: 1600px) { height: calc(var(--vh, 1vh) * 100 - #{gutter(3.7)}); }
  @media (min-width: 1920px) { height: calc(var(--vh, 1vh) * 100 - #{gutter(3.9)}); }

  &__message {
    box-sizing: content-box;
    width: cols(7);
    margin-left: gutter(1);
    margin-top: gutter(6);

    font-size: var(--font-size, 4.8rem);
    line-height: 1.4;
    font-weight: bold;

    max-height: calc(80% - #{gutter(8)});

    @media (max-width: 1000px) {
      width: cols(12);
    }

    @include mobile {
      width: cols(12);
      font-size: $font-size;
      font-size: var(--font-size, $font-size);
      font-size: Max(var(--font-size, $font-size), calc(7vmin));
    }
  }
}
