.about {
  padding: gutter();
  padding-top: 0;

  display: grid;
  grid-template-columns: cols(7) cols(5);
  gap: gutter();

  background: $accent;
  color: $white;

  @include mobile {
    grid-template-columns: 1fr;
  }

  // Add a gradient behing the menu
  &::after {
    content: '';
    pointer-events: none;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: gutter(5);
    background: linear-gradient($accent, var(--color--safari-gradient-hack));
  }

  &__content {
    @include fade-in;
    font-weight: bold;
    @include desktop {
      // Limit the content width
      & > p,
      & > ul { padding-right: gutter(); }
    }
  }

  &__content h2 {
    @include filet(bottom);
    font-size: inherit;
    position: relative;

    padding-bottom: gutter(1/2);
    margin-bottom: gutter(2);

    // Offset for native anchor
    margin-top: gutter(3);
    padding-top: calc(#{gutter(4)} - 4px);
    @media (min-width: 1600px) { padding-top: calc(#{gutter(4)} + 4px); }
    @media (min-width: 1900px) { padding-top: calc(#{gutter(4)} + 1px); }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__content ul {
    list-style: '•\00a0';
    padding-left: gutter();

    p { margin: 0; }

    li {
      margin-bottom: 0.5em;
    }
  }

  &__content figure {
    display: block;
    border: 0;
  }

  &__navigation {
    @include fade-in;
    @include no-select;

    top: gutter(6);
    position: sticky;
    height: min-content;
    font-weight: bold;

    ul {
      @include filet(top);
      padding-top: gutter();
      margin-bottom: gutter();
    }

    @include mobile  {
      a[href^='#']::before {
        content: '↑\00a0';
      }
    }

    a:not(:hover) {
      border: 0;
    }
  }
}
