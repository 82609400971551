.projects {
  padding: gutter();
  padding-top: 0;
  min-height: calc(100vh - #{gutter(6)});

  @include desktop {
    display: grid;
    grid-template-columns: cols(7) cols(5);
    gap: gutter();
    &::after {
      content: '';
      position: fixed;
      height: gutter();
      width: 100%;
      right: 0;
      left: calc(#{gutter()} + #{cols(7)});
      bottom: 0;
      background: white;
    }
  }

  @media (min-width: 1600px) {
    grid-template-columns: cols(6) cols(6);
    &::after { left: calc(#{gutter()} + #{cols(6)}); }
  }

  @media (min-width: 1920px) {
    grid-template-columns: cols(4) cols(8);
    &::after { left: calc(#{gutter()} + #{cols(4)}); }
  }

  &__items {
    // Using grid to enable the order prop on children
    display: grid;
  }

  &__item {}
  &__item.is-disabled {
    order: 999;
    &, * { color: rgba($black, 0.2); }
  }

  &__item {
    figure {
      @include filet(top);
      padding-top: gutter();
      margin-top: gutter(-0.5);

      & + .project-card {
        border-top: 0;
      }
    }

    @include desktop {
      figure {
        display: none;
      }

      .project-card {
        grid-template-columns: minmax(min-content, max-content) 1fr;
      }

      .project-card__date {
        @include easing(600ms, opacity);
      }

      // Display date only on :hover
      &:not(:hover) .project-card__date {
        opacity: 0;
      }
    }
  }

  &__cover {
    position: sticky;
    top: gutter(6);
    height: calc(100vh - #{gutter(7)});

    filter: grayscale(100%);
    animation: saturate 1s forwards;
    animation-delay: 300ms;
    background: rgba($black, 0.1);

    .cover-holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      background-size: cover;
    }

    // Instead of cross-fading (fadeOut hidden, fadeIn hover), we instantly
    // display the cover to show, with the cover to hide placed on top of it
    // (using z-index), then fadeOut the cover to hide.
    .cover-holder.is-hidden {
      @include easing(300ms, opacity);
      pointer-events: none;
      z-index: 2;
      opacity: 0;
    }

    @include mobile {
      display: none;
    }
  }
}
